// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history__list {
  padding: 0 1.5rem 1.5rem;
  background: #f9f9f9;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 0;
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
}
.history__item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.history__control {
  padding: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  background: #f9f9f9;
  border-radius: 8px 8px 0 0;
}
.history__control button[disabled] .share,
.history__control button[disabled] .stop,
.history__control button[disabled] .reset {
  filter: unset;
}
.history__control button .share,
.history__control button .stop,
.history__control button .reset {
  filter: grayscale(100%) brightness(0.4) sepia(0) hue-rotate(0deg) saturate(10);
}

@media (max-width: 640px) {
  .history__list {
    padding: 0.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/history/history.scss"],"names":[],"mappings":"AAGE;EACE,wBAAA;EACA,mBAAA;EACA,0BAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,SAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAFJ;AAKE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHJ;AAME;EACE,eAAA;EACA,WAAA;EACA,aAAA;EACA,yBAAA;EACA,SAAA;EACA,mBAAA;EACA,0BAAA;AAJJ;AAMI;;;EAGE,aAAA;AAJN;AAOI;;;EAGE,8EAAA;AALN;;AAUA;EACE;IACE,eAAA;EAPF;AACF","sourcesContent":["@import '../../assets/vars.scss';\n\n.history {\n  &__list {\n    padding: 0 1.5rem 1.5rem;\n    background: #f9f9f9;\n    border-radius: 0 0 8px 8px;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    height: 0;\n    flex-grow: 1;\n    overflow-y: auto;\n    position: relative;\n  }\n\n  &__item {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n  }\n\n  &__control {\n    padding: 1.5rem;\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    gap: 1rem;\n    background: #f9f9f9;\n    border-radius: 8px 8px 0 0;\n\n    button[disabled] .share,\n    button[disabled] .stop,\n    button[disabled] .reset {\n      filter: unset;\n    }\n\n    button .share,\n    button .stop,\n    button .reset {\n      filter: grayscale(100%) brightness(0.4) sepia(0) hue-rotate(0deg) saturate(10);\n    }\n  }\n}\n\n@media (max-width: $s) {\n  .history__list {\n    padding: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
