import { EffectCallbacks } from '../actions';

// Экземпляр класса для работы с ассистентами
export const assistantsEffectCallbacks = new EffectCallbacks('/assistants');

// Экземпляр класса для отправки диалога на почту
export const dialogEffectCallbacks = new EffectCallbacks('/contacts');

// Экземпляр класса для работы с другими данными
// export const otherEffectCallbacks = new EffectCallbacks('/otherPath');
