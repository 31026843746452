import React from 'react';
import { MessageDefault } from '../components/chat-wraper/components/MessageDefault';
import { Divider, Text, Command } from '../components/ui';

export interface SerializedElement {
  type: string;
  props: Record<string, any>;
}

const getDisplayName = (type: string | React.JSXElementConstructor<any>): string => {
  if (typeof type === 'string') {
    return type;
  }

  const displayName =
    (type as React.ComponentType).displayName || (type as React.ComponentType).name;

  if (!displayName) {
    console.warn('Component type is missing displayName and name:', type);

    return 'Unknown';
  }

  return displayName;
};

export const serializeReactNode = (node: React.ReactNode): SerializedElement => {
  if (React.isValidElement(node)) {
    const { type, props } = node;

    const displayName = getDisplayName(type);

    return {
      type: displayName,
      props,
    };
  }

  throw new Error('Invalid React node');
};

export const deserializeReactNode = (element: SerializedElement): React.ReactNode => {
  const { type, props } = element;

  const componentsMap: Record<string, React.ElementType> = {
    Command,
    Text,
    Divider,
    MessageDefault,
  };

  const Component = componentsMap[type];

  if (!Component) {
    console.error(`Unknown component type: ${type}`);

    return <></>;
  }

  try {
    return <Component {...props} />;
  } catch (error) {
    console.error('Error rendering component:', error);

    return <></>;
  }
};
