import { RequestParams } from '@types';
import queryString from 'query-string';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export type UseQueryParams = (
  defaultParams?: RequestParams
) => [RequestParams, (p: RequestParams, m?: boolean) => void];

export const useQueryParams: UseQueryParams = (defaultParams: RequestParams = {}) => {
  const navigate = useNavigate();
  const { search } = window.location;
  const query = useMemo(() => queryString.parse(search), [search]);

  const setQuery = (newParams: RequestParams, merge = true) => {
    const newQuery = merge ? { ...query, ...newParams, authSessionId: undefined } : newParams;
    const search = queryString.stringify(newQuery);

    navigate({ pathname: window.location.pathname, search });
  };

  useEffect(() => {
    if (defaultParams) {
      setQuery(defaultParams);
    }
  }, [!!defaultParams]);

  return [query, setQuery];
};
