import { dialogEffectCallbacks } from '../effectCallbacksInstances';

export const postDialog = async (payload: any) => {
  try {
    const responce = await dialogEffectCallbacks.create(payload);

    return responce;
  } catch (error) {
    console.error('Error fetching post dialog:', error);
  }
};
