import React from 'react';
import './command.scss';

/**
 * Command
 */
interface CommandProps {
  children: string;
}

/**
 *
 * @param props
 */
const Command: React.FC<CommandProps> = (props: CommandProps) => (
  <div className="command">
    <div className="command__text">
      <dd>You</dd>
      <div className="message">{props.children}</div>
    </div>
  </div>
);

Command.displayName = 'Command';

export default Command;
