// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.text__msg {
  margin-left: 1rem;
}
.text .message {
  color: #333333;
}
.text .text__icon > img {
  width: 2rem;
  height: 2.125rem;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .text .text__icon {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Text/text.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,2BAAA;EACA,WAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,cAAA;AAAJ;AAGE;EACE,WAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAIE;EACE;IACE,aAAA;EAFJ;AACF","sourcesContent":[".text {\n  display: flex;\n  justify-content: flex-start;\n  width: 100%;\n\n  &__msg {\n    margin-left: 1rem;\n  }\n\n  .message {\n    color: #333333;\n  }\n\n  .text__icon > img {\n    width: 2rem;\n    height: 2.125rem;\n    border-radius: 50%;\n  }\n\n  @media (max-width: 768px) {\n    .text__icon {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
