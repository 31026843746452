import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import {
  addHistory,
  removeLastMessage,
  updateLastMessageInHistory,
} from '../../store/history/historySlice';
import { clearMessage } from '../../store/message/messageFromAssistentSlice';
import { sendMessageToAssistent } from '../../common/clientSocket';
import { Text, Divider, Command, Loader } from '../ui';
import { serializeReactNode } from '../../utils/serializeRedux';
import { MessageDefault } from './components/MessageDefault';
import iconSend from './send.svg';
import './input.scss';

const Chat = () => {
  const dispatch = useAppDispatch();
  const { message, messageEvent, isLoading, threadID, redirectedAssistantId } = useAppSelector(
    (state) => state.message
  );
  const { activeAssistant, assistantList } = useAppSelector((state) => state.assistants);
  const history = useAppSelector((state) => state.history.history);
  const inputEl = useRef<HTMLInputElement>(null);
  const refValue = useRef('');
  const [currentMessageId, setCurrentMessageId] = useState<string>();
  const [blockUpdateLastMessage, setBlockUpdateLastMessage] = useState(false);

  useEffect(() => {
    const messageId = history[history.length - 1]?.id;

    setCurrentMessageId(messageId);

    if (history.length === 0 && assistantList.length > 0) {
      dispatch(addHistory(serializeReactNode(<MessageDefault />)));
    }
  }, [history, assistantList]);

  useEffect(() => {
    if (redirectedAssistantId) {
      setBlockUpdateLastMessage(true);
    }
  }, [redirectedAssistantId]);

  useEffect(() => {
    if (inputEl && inputEl.current && !isLoading) {
      inputEl.current.focus();
    }
  }, [isLoading]);

  useEffect(() => {
    if (message && currentMessageId && !messageEvent) {
      const lastMessageContent = history[history.length - 1]?.content;

      if (lastMessageContent.type !== 'Divider') {
        dispatch(
          updateLastMessageInHistory({
            id: currentMessageId!,
            content: serializeReactNode(<Text>{message}</Text>),
            end: true,
          })
        );
      }
    } else if (messageEvent) {
      // dispatch(addHistory(serializeReactNode(<Text>{messageEvent}</Text>)));
      dispatch(clearMessage());
    }
  }, [message, dispatch, currentMessageId, messageEvent]);

  useEffect(() => {
    if (activeAssistant && history.length > 1) {
      dispatch(addHistory(serializeReactNode(<Divider assistentName={activeAssistant.label} />)));

      console.log(blockUpdateLastMessage);

      if (blockUpdateLastMessage) {
        dispatch(removeLastMessage());
      }

      dispatch(addHistory(serializeReactNode(<Text>{''}</Text>)));

      setBlockUpdateLastMessage(false);
    }
  }, [activeAssistant]);

  const changeHandler = () => {
    if (inputEl.current?.value) {
      refValue.current = inputEl.current?.value;
    }
  };

  const handler = () => {
    dispatch(clearMessage());
    dispatch(addHistory(serializeReactNode(<Command>{refValue.current}</Command>)));
    dispatch(addHistory(serializeReactNode(<Text>{''}</Text>)));

    if (activeAssistant?.value && threadID) {
      sendMessageToAssistent(activeAssistant.value, threadID, refValue.current);
    }

    if (inputEl.current) {
      inputEl.current.value = '';
    }

    refValue.current = '';
  };

  const keyDownHandler = (e: React.KeyboardEvent) => {
    if (refValue.current !== '' && e.key === 'Enter') {
      handler();
    }
  };

  return (
    <>
      <div className="input__box">
        {/* Контролы внизу */}
        <input
          className="input__text"
          ref={inputEl}
          type="text"
          disabled={isLoading || !activeAssistant}
          onChange={changeHandler}
          onKeyDown={keyDownHandler}
        />

        {/* Кнопка отправить */}
        <button
          className="input__button"
          onClick={() => {
            if (!!inputEl.current?.value && refValue.current !== '') {
              handler();
            }
          }}
          disabled={isLoading || !activeAssistant}
        >
          {isLoading ? <Loader /> : <img src={iconSend} alt="Send" />}
        </button>
      </div>
      <div className="input__desc">
        This AI chat service is provided for general information and entertainment purposes only.
        While we strive to provide accurate and helpful responses, we make no guarantees regarding
        the reliability or accuracy of the information provided. Users should not rely solely on
        this service for critical decision-making or as a substitute for professional advice.
        Sending messages to the chat you accepting this disclaimer terms.
      </div>
    </>
  );
};

export default Chat;
