import React from 'react';
import './divider.scss';

interface DividerProps {
  assistentName: string;
}

const Divider: React.FC<DividerProps> = ({ assistentName }: DividerProps) => (
  <div className="divider">
    <span className="divider__text">{`${assistentName} entering chat`}</span>
  </div>
);

Divider.displayName = 'Divider';

export default Divider;
