// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  flex-direction: row;
  flex-wrap: wrap;
}
.buttons.disable > button {
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Buttons/buttons.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,2BAAA;EACA,WAAA;EACA,mBAAA;EACA,eAAA;AACF;AAEI;EACE,oBAAA;AAAN","sourcesContent":[".buttons {\n  margin: 0.5rem 0;\n  display: flex;\n  justify-content: flex-start;\n  gap: 0.5rem;\n  flex-direction: row;\n  flex-wrap: wrap;\n\n  &.disable {\n    > button {\n      pointer-events: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
