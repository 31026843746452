// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid;
  border-color: white;
  border-right-color: #0e4774;
  border-left-color: #0e4774;
  border-top-color: #0e4774;
  animation: custom-spinner 3s infinite linear;
}
.custom-spinner__wrapper {
  display: flex;
  justify-content: center;
}

@keyframes custom-spinner {
  to {
    transform: rotate(1turn);
  }
}
@media (max-width: 1200px) {
  .custom-spinner__margin {
    margin-top: var(--margin-custom-spinner);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ui/loader/loader.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,2BAAA;EACA,0BAAA;EACA,yBAAA;EACA,4CAAA;AADF;AAGE;EACE,aAAA;EACA,uBAAA;AADJ;;AASA;EACE;IACE,wBAAA;EANF;AACF;AASA;EAEI;IACE,wCAAA;EARJ;AACF","sourcesContent":["@import '../../../assets/vars';\n\n.custom-spinner {\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  border: 5px solid;\n  border-color: white;\n  border-right-color: #0e4774;\n  border-left-color: #0e4774;\n  border-top-color: #0e4774;\n  animation: custom-spinner 3s infinite linear;\n\n  &__wrapper {\n    display: flex;\n    justify-content: center;\n  }\n\n  // &__margin {\n  //   margin-top: 120px;\n  // }\n}\n\n@keyframes custom-spinner {\n  to {\n    transform: rotate(1turn);\n  }\n}\n\n@media (max-width: $m) {\n  .custom-spinner {\n    &__margin {\n      margin-top: var(--margin-custom-spinner);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
