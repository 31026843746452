// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.command {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.command .command__text {
  margin-right: 1rem;
}
.command .command__text .message {
  color: #666666;
  border-radius: 1rem;
  background-color: #ffffff;
  padding: 0.5rem 1rem;
}
.command .command__text dd {
  color: #333333;
  margin-left: auto;
  display: table;
}
.command .command__icon > img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Command/command.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,WAAA;AACF;AACE;EACE,kBAAA;AACJ;AACI;EACE,cAAA;EACA,mBAAA;EACA,yBAAA;EACA,oBAAA;AACN;AAEI;EACE,cAAA;EACA,iBAAA;EACA,cAAA;AAAN;AAIE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAFJ","sourcesContent":[".command {\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n\n  .command__text {\n    margin-right: 1rem;\n\n    .message {\n      color: #666666;\n      border-radius: 1rem;\n      background-color: #ffffff;\n      padding: 0.5rem 1rem;\n    }\n\n    dd {\n      color: #333333;\n      margin-left: auto;\n      display: table;\n    }\n  }\n\n  .command__icon > img {\n    width: 2rem;\n    height: 2rem;\n    border-radius: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
