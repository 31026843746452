import { Link } from 'react-router-dom';
import './footer.scss';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation('common');

  return (
    <footer>
      <div className="somai__footer">
        <span>© {new Date().getFullYear()} SOMAÍ Pharmaceuticals LTD. All rights reserved</span>
        <nav>
          <Link to="/privacy-policy" target="_blank">
            {t('Privacy policy')}
          </Link>
          <Link to="/terms-of-service" target="_blank">
            {t('Terms of Service')}
          </Link>
          <Link to="/cookie-policy" target="_blank">
            {t('Cookie Policy')}
          </Link>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
