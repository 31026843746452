// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input__box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  padding-block: 0.5rem;
  padding-inline: 1.5rem 0.5rem;
  border-radius: 8px;
  background: #f9f9f9;
}
.input__text {
  all: unset;
  font-size: 0.875rem;
  width: 100%;
  word-wrap: break-word;
}
.input__button {
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 8px;
  margin-left: 0.5rem;
  cursor: pointer;
  transition: 0.5s;
}
@media (max-width: 640px) {
  .input__button {
    padding: 0;
  }
}
.input__button:hover {
  transform: scale(1.2);
  transition: 0.5s;
}
.input__button > img {
  width: 1.5rem;
  height: 1.5rem;
}
.input__desc {
  margin-block: 0.5rem 1.5rem;
  font-size: 0.5rem;
  line-height: 0.625rem;
  color: #999999;
}`, "",{"version":3,"sources":["webpack://./src/components/chat-wraper/input.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;EACA,qBAAA;EACA,6BAAA;EACA,kBAAA;EACA,mBAAA;AAFJ;AAKE;EACE,UAAA;EACA,mBAAA;EACA,WAAA;EACA,qBAAA;AAHJ;AAME;EACE,gBAAA;EACA,6BAAA;EACA,SAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AAJJ;AAMI;EATF;IAUI,UAAA;EAHJ;AACF;AAKI;EACE,qBAAA;EACA,gBAAA;AAHN;AAMI;EACE,aAAA;EACA,cAAA;AAJN;AAQE;EACE,2BAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;AANJ","sourcesContent":["@import '../../assets/vars.scss';\n\n.input {\n  &__box {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-items: center;\n    justify-content: space-between;\n    margin-top: 1.5rem;\n    padding-block: 0.5rem;\n    padding-inline: 1.5rem 0.5rem;\n    border-radius: 8px;\n    background: #f9f9f9;\n  }\n\n  &__text {\n    all: unset;\n    font-size: 0.875rem;\n    width: 100%;\n    word-wrap: break-word;\n  }\n\n  &__button {\n    appearance: none;\n    background-color: transparent;\n    border: 0;\n    padding: 8px;\n    margin-left: 0.5rem;\n    cursor: pointer;\n    transition: 0.5s;\n\n    @media (max-width: $s) {\n      padding: 0;\n    }\n\n    &:hover {\n      transform: scale(1.2);\n      transition: 0.5s;\n    }\n\n    > img {\n      width: 1.5rem;\n      height: 1.5rem;\n    }\n  }\n\n  &__desc {\n    margin-block: 0.5rem 1.5rem;\n    font-size: 0.5rem;\n    line-height: 0.625rem;\n    color: #999999;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
