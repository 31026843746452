import { FC } from 'react';
import { HistoryItem } from '../../../store/history/historySlice';
import { deserializeReactNode } from '../../../utils/serializeRedux';

interface RenderChatProps {
  history: HistoryItem[];
}

export const RenderChat: FC<RenderChatProps> = ({ history }) => (
  <>
    {history?.map((item) => (
      <div key={item.id} className={`history__item${item.end ? '-end' : ''}`}>
        {item?.content && deserializeReactNode(item.content)}
      </div>
    ))}
  </>
);
