import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import Router from './Router';
import { store } from './store';
import { ErrorBoundary, Footer, Header, LoginModal } from './components';

function App() {
  // const { i18n } = useTranslation();
  const [isIframe, setIsIframe] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // useEffect(() => {
  //   localStorage.setItem('language', i18n.language);
  // }, [i18n.language]);

  useEffect(() => {
    const isWebView = /WebView|FB|Instagram/i.test(navigator.userAgent);

    if (isWebView) {
      setIsIframe(isWebView);
    } else if (window.self !== window.top) {
      setIsIframe(true);
    }
  }, []);

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <BrowserRouter>
          <Header isIframe={isIframe} />
          {isModalOpen && <LoginModal onClose={handleCloseModal} />}
          <div className="somai__content">
            <Router />
          </div>
          <Footer />
        </BrowserRouter>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
