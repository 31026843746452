// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.somai__footer {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  margin-inline: auto;
}
@media (max-width: 640px) {
  .somai__footer {
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
    font-size: 0.75rem;
    padding: 1rem;
  }
}
.somai__footer > nav {
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
}
@media (max-width: 640px) {
  .somai__footer > nav {
    font-size: 0.75rem;
  }
}
.somai__footer > nav a {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.scss","webpack://./src/assets/vars.scss"],"names":[],"mappings":"AAEA;EACE,iBCFE;EDGF,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AADF;AAGE;EARF;IASI,sBAAA;IACA,kBAAA;IACA,WAAA;IACA,kBAAA;IACA,aAAA;EAAF;AACF;AAEE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAAJ;AAEI;EALF;IAMI,kBAAA;EACJ;AACF;AACI;EACE,YAAA;AACN","sourcesContent":["@import '../../assets/vars.scss';\n\n.somai__footer {\n  max-width: $m;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 1.5rem;\n  margin-inline: auto;\n\n  @media (max-width: $s) {\n    flex-direction: column;\n    align-items: start;\n    gap: 0.5rem;\n    font-size: 0.75rem;\n    padding: 1rem;\n  }\n\n  > nav {\n    display: flex;\n    gap: 1rem;\n    font-size: 0.875rem;\n\n    @media (max-width: $s) {\n      font-size: 0.75rem;\n    }\n\n    a {\n      color: white;\n    }\n  }\n}\n","$s: 640px;\n$m: 1200px;\n$l: 1366px;\n$xl: 1440px;\n$xxl: 1600px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
