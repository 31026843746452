import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SelectType } from '../../components/ui/Select';

export interface AssistantsState {
  assistantList: SelectType[];
  activeAssistant: SelectType | null;
}

const initialState: AssistantsState = {
  assistantList: [],
  activeAssistant: null,
};

export const assistantsSlice = createSlice({
  name: 'assistants',
  initialState,
  reducers: {
    getAssistants: (state, action: PayloadAction<SelectType[]>) => {
      state.assistantList = action.payload;
    },
    setActiveAssistant: (state, action: PayloadAction<SelectType | null>) => {
      state.activeAssistant = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { getAssistants, setActiveAssistant } = assistantsSlice.actions;

export default assistantsSlice.reducer;
