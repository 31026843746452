import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Modal,
  TextField,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppSelector } from '../../hooks/storeHooks';
import { RootState } from '../../store';
import { convertMessagesToText } from '../../utils/formatMessages';
import { postDialog } from '../../services/paths/loader';
import './saveDialogModal.scss';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const validationSchema = yup.object({
  fullname: yup.string().required('Full name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  professional: yup.boolean(),
  privacy_policy_accepted: yup.boolean().oneOf([true], 'Privacy policy must be accepted'),
});

export const SaveDialogModal: React.FC<ModalProps> = ({ open, onClose }) => {
  const history = useAppSelector((state: RootState) => state.history.history);
  const { isLoading } = useAppSelector((state) => state.message);

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      professional: false,
      privacy_policy_accepted: false,
      privacy_policy_accepted_time: new Date(),
      chat_history: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const formattedText = convertMessagesToText(history);
      const payload = {
        ...values,
        chat_history: formattedText,
      };

      const resp = await postDialog(payload);

      if (resp) {
        onClose();
      }
    },
  });

  return (
    <Modal open={open} onClose={onClose}>
      <div className="modal_content">
        <header>
          Save your dialog
          <CancelIcon
            sx={{
              cursor: 'pointer',
            }}
            color="disabled"
            onClick={onClose}
          />
        </header>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <p>Enter your name and email to receive a copy of this dialog to email.</p>
          <Grid item xs={12} md={6} sx={{ mt: '1.5rem' }}>
            <TextField
              fullWidth
              variant="standard"
              type="text"
              label="Full name"
              placeholder="Full name"
              {...formik.getFieldProps('fullname')}
              error={formik.touched.fullname && Boolean(formik.errors.fullname)}
              helperText={formik.touched.fullname && formik.errors.fullname}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: '1rem' }}>
            <TextField
              fullWidth
              variant="standard"
              type="email"
              label="Email"
              placeholder="name@mail.com"
              {...formik.getFieldProps('email')}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: '1.5rem' }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.professional}
                    {...formik.getFieldProps('professional')}
                  />
                }
                label="I’m medical professional"
              />
              <FormControl
                error={
                  formik.touched.privacy_policy_accepted &&
                  Boolean(formik.errors.privacy_policy_accepted)
                }
                component="fieldset"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.privacy_policy_accepted}
                      {...formik.getFieldProps('privacy_policy_accepted')}
                    />
                  }
                  label={
                    <span>
                      Accept{' '}
                      <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                        privacy policy
                      </a>
                    </span>
                  }
                />
                {formik.touched.privacy_policy_accepted &&
                  formik.errors.privacy_policy_accepted && (
                    <FormHelperText>{formik.errors.privacy_policy_accepted}</FormHelperText>
                  )}
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ mt: '1.5rem', display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}
          >
            <Button
              type="button"
              variant="outlined"
              onClick={onClose}
              style={{ color: '#3C5981', borderBlockColor: '#3C5981' }}
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{ background: '#3C5981' }}
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={16} style={{ color: 'white' }} />}
            >
              SEND
            </Button>
          </Grid>
        </Box>
      </div>
    </Modal>
  );
};
