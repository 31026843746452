import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { deleteChat, stopChat } from '../../../common/clientSocket';
import { SaveDialogModal } from '../../save-dialog-modal/SaveDialogModal';
import iconShare from '../../../assets/img/share.svg';
import iconStop from '../../../assets/img/stop.svg';
import iconDelete from '../../../assets/img/delete.svg';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { clearHistory } from '../../../store/history/historySlice';
import {
  clearMessage,
  setLoading,
  clearThreadID,
} from '../../../store/message/messageFromAssistentSlice';
import { setActiveAssistant } from '../../../store/assistants/assistantsSlice';

export const ControlButtons = () => {
  const dispatch = useAppDispatch();
  const [, setQuery] = useQueryParams();
  const activeAssistant = useAppSelector((state) => state.assistants.activeAssistant);
  const { isLoading } = useAppSelector((state) => state.message);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleShareChat = () => {
    setIsModalOpen(true);
  };

  const handleStopChat = () => {
    stopChat();
    dispatch(setLoading(false));
  };

  const handleResetChat = () => {
    if (activeAssistant) {
      setQuery({}, false);
      dispatch(setActiveAssistant(null));
      dispatch(clearHistory());
      dispatch(clearMessage());
      dispatch(clearThreadID());
      // resetChat(activeAssistant.value);
      deleteChat();
    }
  };

  return (
    <>
      <div className="history__control">
        <button onClick={handleShareChat} disabled={!activeAssistant}>
          <img className="share" src={iconShare} alt="share" />
        </button>
        <button onClick={handleStopChat} disabled={!isLoading}>
          <img className="stop" src={iconStop} alt="stop" />
        </button>
        <button onClick={handleResetChat} disabled={!activeAssistant}>
          <img className="reset" src={iconDelete} alt="reset" />
        </button>
      </div>

      <SaveDialogModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};
