import { useState } from 'react';
import { useAppSelector } from '../../../hooks/storeHooks';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { Buttons, Text } from '../../ui';
import './messageDefault.scss';

export const MessageDefault = () => {
  const assistants = useAppSelector((state) => state.assistants.assistantList);
  const [, setQuery] = useQueryParams();
  const history = useAppSelector((state) => state.history.history);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  const handlerAsistent = (value: string) => {
    setQuery({ assistant_id: value });
  };

  if (history.length > 1) {
    return <></>;
  }

  return (
    <>
      {showAdditionalInfo ? (
        <Text>
          May I kindly ask you to choose your country of residence? Please note that for now, I am
          equipped to discuss matters related to Australia, Germany, and the UK. I apologize for any
          inconvenience caused, and rest assured that information regarding other countries will be
          made available soon. Please stay in touch, you can subscribe to our newsletter to find out
          first when new countries will be available.
          <Buttons>
            {assistants.slice(1).map((item) => (
              <button
                key={item.value}
                className="history_button"
                onClick={() => handlerAsistent(item.value)}
              >
                {item.label}
              </button>
            ))}
          </Buttons>
        </Text>
      ) : (
        <Text>
          Hello and welcome to SOMAÍ virtual assistant. I am here to help you and provide you with
          any information you may need. To better serve you, please specify if your query is related
          to a specific SOMAÍ product or general information about medical cannabis
          <Buttons>
            <button className="history_button" onClick={() => handlerAsistent(assistants[0].value)}>
              {assistants[0].label}
            </button>
            <button className="history_button" onClick={() => setShowAdditionalInfo(true)}>
              Medical Cannabis
            </button>
          </Buttons>
        </Text>
      )}
    </>
  );
};
