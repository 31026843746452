import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { SelectAssistent } from '../ui';
import { ReactComponent as LogoSvg } from '../../assets/img/logo_82.svg';

const Header: React.FC<{ isIframe: boolean }> = ({ isIframe }) => {
  const { t } = useTranslation('common');
  const [menuOpen, setMenuOpen] = useState(false);
  const navRef = useRef<HTMLDivElement | null>(null);
  const hamburgerRef = useRef<HTMLDivElement | null>(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event: any) => {
    if (
      navRef.current &&
      !navRef.current.contains(event.target as Node) &&
      hamburgerRef.current &&
      !hamburgerRef.current.contains(event.target as Node)
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside);

    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, []);

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <header>
      <div
        className={cn('somai__header', {
          iframe: isIframe,
        })}
      >
        <div className="somai__header_block">
          <LogoSvg onClick={() => history.back()} />

          <SelectAssistent />

          <div
            ref={hamburgerRef}
            className={`hamburger ${menuOpen ? 'open' : ''}`}
            onClick={toggleMenu}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
          <nav ref={navRef} className={menuOpen ? 'open' : ''}>
            <Link
              to="https://somaipharma.eu/products?utm_source=assistant"
              target={'_blank'}
              rel={'noopener noreferrer'}
              onClick={handleLinkClick}
            >
              {t('Products')}
            </Link>
            <Link
              to="https://somaipharma.eu/news?utm_source=assistant"
              target={'_blank'}
              rel={'noopener noreferrer'}
              onClick={handleLinkClick}
            >
              {t('News')}
            </Link>
            <Link
              to="https://somaipharma.eu/contacts?utm_source=assistant"
              target={'_blank'}
              rel={'noopener noreferrer'}
              onClick={handleLinkClick}
            >
              {t('Contacts')}
            </Link>
          </nav>
        </div>
        <div className="somai__delimiter">
          <SelectAssistent />
        </div>
      </div>
    </header>
  );
};

export default Header;
