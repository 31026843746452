import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from '../store';
import { setLoading } from '../store/message/messageFromAssistentSlice';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ROUTE,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-type': 'application/json;charset=utf-8',
  },
  timeout: 60000,
});

api.interceptors.request.use((config: AxiosRequestConfig | any) => {
  store.dispatch(setLoading(true));

  return {
    ...config,
    headers: {
      ...config.headers,
    },
  };
});

api.interceptors.response.use(
  (data: AxiosResponse) => {
    store.dispatch(setLoading(false));

    return data;
  },
  (error: AxiosError) => {
    store.dispatch(setLoading(false));

    if (error.response?.status === 400) {
      return { warning: error.response };
    }

    return { error: error.response };
  }
);

export default api;
