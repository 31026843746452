import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { getAssistants, setActiveAssistant } from '../../../store/assistants/assistantsSlice';
import './select-assistent.scss';
import { loadAssistantsData } from '../../../services/paths/loader';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { clearMessage } from '../../../store/message/messageFromAssistentSlice';
import { socket, useConnectSocket } from '../../../common/clientSocket';

export type SelectType = {
  value: string;
  label: string;
};

export const SelectAssistent = () => {
  const { t } = useTranslation('common');
  const [query, setQuery] = useQueryParams();
  const dispatch = useAppDispatch();
  const assistants = useAppSelector((state) => state.assistants.assistantList);
  const activeAssistant = useAppSelector((state) => state.assistants.activeAssistant);
  const redirectedAssistantId = useAppSelector((state) => state.message.redirectedAssistantId);
  const threadID = useAppSelector((state) => state.message.threadID);

  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

  useEffect(() => {
    (async () => {
      try {
        const assistantsForSelect = await loadAssistantsData();
        dispatch(getAssistants(assistantsForSelect || []));
      } catch (error) {
        console.error('Error fetching assistants list:', error);
      }
    })();
  }, []);

  useEffect(() => {
    if (
      assistants.length > 0 &&
      query.assistant_id &&
      query.assistant_id !== activeAssistant?.value
    ) {
      const queryActiveAssistant = assistants.find((item) => item.value === query.assistant_id);

      if (queryActiveAssistant) {
        dispatch(setActiveAssistant(queryActiveAssistant));
      }
    }
  }, [assistants, query.assistant_id, activeAssistant?.value]);

  useEffect(() => {
    if (redirectedAssistantId) {
      setQuery({ assistant_id: redirectedAssistantId, thread_id: threadID });
    } else if (
      !redirectedAssistantId &&
      activeAssistant &&
      activeAssistant.value !== query.assistant_id
    ) {
      setQuery({ assistant_id: activeAssistant.value });
    } else if (query.thread_id !== threadID) {
      setQuery({ ...query, thread_id: threadID });
    }
  }, [redirectedAssistantId, threadID, activeAssistant]);

  const customStyles = {
    control: () => ({
      display: isSmallScreen ? 'flex' : 'inline-flex',
      alignItems: 'center',
      border: '1px solid #C5DCE8',
      borderRadius: 4,
      background: '#FFFFFF',
      color: '#666666',
      width: 'inherit',
      minWidth: '164px',
    }),
    indicatorsContainer: () => ({
      padding: 8,
    }),
    valueContainer: () => ({
      width: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      marginLeft: 10,
    }),
    dropdownIndicator: () => ({
      color: '#666666',
      display: 'flex',

      transition: 'color 150ms',
      ':hover': {
        color: '#258FF9',
      },
      svg: {
        height: 24,
        width: 24,
      },
    }),
  };

  const handlerSelect = async (selected: any) => {
    if (!socket || socket.disconnected) {
      await useConnectSocket();
    }

    const selectedAssistant = selected as SelectType;
    dispatch(setActiveAssistant(selectedAssistant));
    setQuery({ assistant_id: selectedAssistant.value }, false);
    dispatch(clearMessage());
  };

  const isSearchable = false;

  return (
    <Select
      className="select"
      placeholder={t('Select Assistant')}
      value={activeAssistant}
      onChange={handlerSelect}
      options={assistants}
      styles={customStyles}
      isSearchable={isSearchable}
    />
  );
};
