import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface MessageState {
  message: string;
  messageEvent: string;
  threadID?: string;
  isLoading: boolean;
  redirectedAssistantId?: string;
}

const initialState: MessageState = {
  message: '',
  messageEvent: '',
  isLoading: false,
};

const messageFromAssistentSlice = createSlice({
  name: 'message-form-assistent',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<string>) => {
      state.message += action.payload;
      state.messageEvent = '';
      state.isLoading = false;
    },
    clearMessage: (state) => {
      state.message = '';
      state.messageEvent = '';
      state.redirectedAssistantId = undefined;
      state.isLoading = false;
    },
    addMessageEvent: (state, action: PayloadAction<string>) => {
      state.messageEvent = action.payload;
      state.message = '';
      state.isLoading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setThreadID: (state, action: PayloadAction<string>) => {
      state.threadID = action.payload;
    },
    clearThreadID: (state) => {
      state.threadID = undefined;
    },
    setRedirectedAssistantID: (state, action: PayloadAction<string>) => {
      state.redirectedAssistantId = action.payload;
    },
  },
});

export const {
  addMessage,
  addMessageEvent,
  clearMessage,
  setLoading,
  setThreadID,
  setRedirectedAssistantID,
  clearThreadID,
} = messageFromAssistentSlice.actions;

export default messageFromAssistentSlice.reducer;
