import { assistantsEffectCallbacks } from '../effectCallbacksInstances';

export const loadAssistantsData = async () => {
  try {
    const assistantsList = await assistantsEffectCallbacks.list({});

    if (Array.isArray(assistantsList)) {
      const assistantsForSelect = assistantsList.map((item: any) => ({
        label: item.name,
        value: item._id,
      }));

      return assistantsForSelect;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching assistants list:', error);
    // throw error;
  }
};
