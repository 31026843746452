// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_content {
  width: 50vw;
  min-width: 300px;
  max-width: 500px;
  max-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  background: white;
  border-radius: 8px;
  padding: 1rem;
}
@media (max-width: 640px) {
  .modal_content {
    width: 100%;
    height: calc(100% - 74px);
    top: 74px;
    left: 0;
    transform: none;
  }
}
.modal_content header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin-bottom: 2rem;
}
.modal_content p,
.modal_content span {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/save-dialog-modal/saveDialogModal.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;AADF;AAGE;EAdF;IAeI,WAAA;IACA,yBAAA;IACA,SAAA;IACA,OAAA;IACA,eAAA;EAAF;AACF;AAEE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AAAJ;AAGE;;EAEE,eAAA;AADJ","sourcesContent":["@import '../../assets/vars.scss';\n\n.modal_content {\n  width: 50vw;\n  min-width: 300px;\n  max-width: 500px;\n  max-height: 90vh;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  outline: none;\n  background: white;\n  border-radius: 8px;\n  padding: 1rem;\n\n  @media (max-width: $s) {\n    width: 100%;\n    height: calc(100% - 74px);\n    top: 74px;\n    left: 0;\n    transform: none;\n  }\n\n  header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font-size: 16px;\n    margin-bottom: 2rem;\n  }\n\n  p,\n  span {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
