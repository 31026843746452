import { useRef, useEffect } from 'react';
import { useAppSelector } from '../../hooks/storeHooks';
import { RootState } from '../../store';
import { RenderChat } from './components/RenderChat';
import { ControlButtons } from './components/ControlButtons';
import './history.scss';

const History = () => {
  const history = useAppSelector((state: RootState) => state.history.history);

  const historyEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (historyEl && historyEl.current) {
      historyEl.current.scrollBy({
        top: 1000,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [history]);

  return (
    <>
      <ControlButtons />
      <div className="history__list" id="history" ref={historyEl}>
        <RenderChat history={history} />
      </div>
    </>
  );
};

export default History;
