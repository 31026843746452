import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const HomePage = React.lazy(() => import('./pages/HomePage'));
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));

const StaticPage: React.FC<{ src: string }> = ({ src }) => (
  <iframe
    src={src}
    style={{ width: '100%', height: 'inherit', border: 'none' }}
    title="Static Page"
  />
);

const Router = () => (
  <Suspense fallback={null}>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/privacy-policy" element={<StaticPage src="/static/privacy-policy.html" />} />
      <Route path="/cookie-policy" element={<StaticPage src="/static/cookie-policy.html" />} />
      <Route
        path="/terms-of-service"
        element={<StaticPage src="/static/terms-of-service.html" />}
      />
      <Route path="/404" element={<NotFoundPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </Suspense>
);

export default Router;
