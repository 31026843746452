import { configureStore } from '@reduxjs/toolkit';
import historyReducer from './history/historySlice';
import assistantsReducer from './assistants/assistantsSlice';
import messageFromAssistentReducer from './message/messageFromAssistentSlice';

// Создаем хранилище
export const store = configureStore({
  reducer: {
    history: historyReducer,
    assistants: assistantsReducer,
    message: messageFromAssistentReducer,
  },
});

// Экспортируем типы
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
