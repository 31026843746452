import cn from 'classnames';
import { FC } from 'react';
import './loader.scss';

type Props = {
  isDisableMargin?: boolean;
};

export const Loader: FC<Props> = ({ isDisableMargin = false }) => (
  <div
    className={cn(`custom-spinner__wrapper`, {
      'custom-spinner__margin': !isDisableMargin,
    })}
  >
    <div className="custom-spinner"></div>
  </div>
);
