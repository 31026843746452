import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { SerializedElement } from '../../utils/serializeRedux';

export interface HistoryItem {
  id: string;
  content: SerializedElement;
  createdAt: string;
  end?: boolean;
}

interface HistoryState {
  history: HistoryItem[];
}

const initialState: HistoryState = {
  history: [],
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    addHistory: (state, action: PayloadAction<SerializedElement>) => {
      const id = uuidv4();
      const createdAt = new Date().toISOString();
      state.history.push({ id, content: action.payload, createdAt });

      return state;
    },
    updateLastMessageInHistory: (
      state,
      action: PayloadAction<{ id: string; content: SerializedElement; end?: boolean }>
    ) => {
      const { id, content, end } = action.payload;
      const item = state.history.find((item) => item.id === id);

      if (item) {
        item.content = content;

        if (end) {
          item.end = true;
        }
      }
    },
    removeLastMessage: (state) => {
      if (state.history.length > 1) {
        state.history.splice(-2, 1);
      }
    },
    clearHistory: (state) => {
      state.history = [];
    },
  },
});

export const { addHistory, updateLastMessageInHistory, removeLastMessage, clearHistory } =
  historySlice.actions;

export default historySlice.reducer;
