import React from 'react';
import cn from 'classnames';
import './buttons.scss';
import { useAppSelector } from '../../../hooks/storeHooks';

interface ButtonProps {
  children: React.ReactNode | React.ReactNode[];
}

export const Buttons: React.FC<ButtonProps> = ({ children }: ButtonProps) => {
  const { isLoading } = useAppSelector((state) => state.message);

  return (
    <div
      className={cn('buttons', {
        disable: isLoading,
      })}
    >
      {children}
    </div>
  );
};
